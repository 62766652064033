import React from 'react'
import {Navigate, Route, Routes} from "react-router-dom"
import UserManager from "components/admin/user/UserManager"
import ExportManager from "components/admin/exports/ExportManager"
import PeriodManager from "components/admin/periods/PeriodManager"
import WorkspaceManager from "../workspaceManager/WorkspaceManager";
import DatamodelManager from "../datamodelManager/DatamodelManager";
import DatamodelDetail from "../datamodelManager/DatamodelDetail";
import UserDetails from "./user/UserDetails";
import WorkspaceDetail from "../workspaceManager/WorkspaceDetail";
import AdminHome from "./Admin.Home";


const AdminContainer = () => {
  return <>
    <Routes>
      <Route path="user" element={<UserManager/>}/>
      <Route path="user/:uid" element={<UserDetails/>}/>
      <Route path="workspace/:id" element={<WorkspaceDetail/>}/>
      <Route path="workspaces" element={<WorkspaceManager />}/>
      <Route path="datamodel" element={<DatamodelManager />}/>
      <Route path="datamodel/:id" element={<DatamodelDetail />}/>
      <Route path="exports" element={<ExportManager/>}/>
      <Route path="periods" element={<PeriodManager/>}/>
      <Route
        path="*"
        element={<AdminHome/>}
      />
    </Routes>
  </>
}

export default AdminContainer;
