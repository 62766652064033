import {FlexContainer, FlexDiv, MenuWithTopBorder} from "../MainLayout.Sider.style";
import {IconContainer} from "@biron-data/react-components";
import {AdjustmentsIcon, BookOpenIcon, SupportIcon, UserCircleIcon} from "@heroicons/react/outline";
import Language from "../../../language";
import React, {memo, useCallback, useMemo} from "react";
import {useSelector} from "react-redux";
import {getPermission, getUser} from "../../../redux/appEnvironment.selector";
import {useNavigate} from "react-router-dom";
import {NormalizedDashboardTypes} from "../../../schemas/dashboard";
import {AuthContextProps} from "react-oidc-context";
import {ExpandedWorkspace} from "../../../redux/models/workspace";
import {PermissionsEnum} from "../../../redux/models/appEnvironment";

interface Props {
  currentWorkspace: ExpandedWorkspace | undefined
  selectedBottomMenuItem?: string[]
  onSelectBottomMenuItem: (selection: { selectedKeys: string[] }) => void
  isTicketUser?: boolean
  onLogout: ({auth}: { auth?: AuthContextProps }) => void
  userCanSetPowerUser?: boolean
  userCanManageOnEnvironment?: boolean
  workspaceUriBuilder: (pathOrDashboard?: string | NormalizedDashboardTypes) => string
}

const AdminMenu = memo<Props>(({
                                 currentWorkspace,
                                 selectedBottomMenuItem,
                                 onSelectBottomMenuItem,
                                 onLogout,
                                 userCanManageOnEnvironment,
                                 isTicketUser,
                                 workspaceUriBuilder,
                               }) => {
  const userInfo = useSelector(getUser)
  const navigate = useNavigate()
  const getPermissionSelector = useSelector(getPermission)

  const isAdmin = getPermissionSelector(PermissionsEnum.userCanManageOnEnvironment)
  const isSuperAdmin = getPermissionSelector(PermissionsEnum.userCanManageAdvancedFeature)

  const handleClick = useCallback(({key}: { key: string }) => {
    switch (key) {
      case "logout":
        onLogout({})
        break
      case "/delegate/kb":
        window.open("/delegate/kb", '_blank')
        break
      case `help`:
        navigate(workspaceUriBuilder(`help`))
        break
      case workspaceUriBuilder(`help`):
        navigate(workspaceUriBuilder(`help`))
        break
      case "/delegate/tickets":
        window.open("/delegate/tickets", '_blank')
        break
      case workspaceUriBuilder('admin/user'):
        navigate(workspaceUriBuilder('admin/user'))
        break
      case workspaceUriBuilder('admin/workspace'):
        navigate(workspaceUriBuilder(`admin/workspace/${currentWorkspace?.id}`))
        break
      case workspaceUriBuilder('admin/datamodel'):
        navigate(workspaceUriBuilder('admin/datamodel'))
        break
      case workspaceUriBuilder(`admin/exports`):
        navigate(workspaceUriBuilder(`admin/exports`))
        break
      case workspaceUriBuilder(`admin/periods`):
        navigate(workspaceUriBuilder(`admin/periods`))
        break
      case workspaceUriBuilder(`admin/workspaces`):
        navigate(workspaceUriBuilder(`admin/workspaces`))
        break
      default:
    }
  }, [currentWorkspace?.id, navigate, onLogout, workspaceUriBuilder])

  const availableAdminMenu = useMemo(() => {
    const baseManagerMenu = [{
      key: workspaceUriBuilder(`admin/workspace`),
      label: currentWorkspace?.name,
    },]
    const adminMenu = [
        ...baseManagerMenu,
        {
          key: workspaceUriBuilder('admin/user'),
          label: Language.get('admin.user.breadcrumb.title'),
        },
        {
          key: workspaceUriBuilder(`admin/workspaces`),
          label: Language.get('admin.workspaces.sider-title'),
        },
        {
          key: workspaceUriBuilder(`admin/datamodel`),
          label: Language.get('admin.datamodels.breadcrumb.title'),
        }
      ]
    if (isAdmin && !isSuperAdmin) {
      return adminMenu
    } else if (isAdmin && isSuperAdmin) {
      return [
        ...adminMenu,
        {
          key: workspaceUriBuilder(`admin/exports`),
          label: Language.get("admin.export.sider-title"),
        },
        {
          key: workspaceUriBuilder(`admin/periods`),
          label: Language.get("admin.period.sider-title"),
        }
      ]
    }
    return baseManagerMenu
  }, [isAdmin, isSuperAdmin, workspaceUriBuilder])

  return <MenuWithTopBorder
    theme={"biron" as any /* Menu types does not register additional themes */}
    mode="vertical"
    defaultSelectedKeys={[location.pathname]}
    subMenuOpenDelay={.5}
    triggerSubMenuAction={"click"}
    selectedKeys={selectedBottomMenuItem}
    onSelect={onSelectBottomMenuItem}
    onClick={(e) => handleClick(e)}
    items={[
      {
        key: userInfo?.fullName ?? "",
        label: userInfo?.fullName,
        icon: <FlexContainer><IconContainer margin={10}><UserCircleIcon/></IconContainer></FlexContainer>,
        children: [{
          key: "logout",
          label: Language.get('navigation-log-out'),
        }],
      },
      {
        key: 'navigation-help',
        label: Language.get('navigation-help'),
        icon: <FlexContainer><IconContainer margin={10}><SupportIcon/></IconContainer></FlexContainer>,
        children: [{
          key: "/delegate/kb",
          label: Language.get('navigation-knowledge-base'),
        }, {
          key: workspaceUriBuilder(`help`),
          label: Language.get('definitions-link-label'),
        }, ...(isTicketUser ? [{
          key: "/delegate/tickets",
          label: Language.get('navigation-tickets'),
        }] : [])],
      },
      {
        key: "help",
        label: Language.get('definitions-link-label'),
        icon: <FlexContainer><IconContainer margin={10}><BookOpenIcon/></IconContainer></FlexContainer>,
      },
      ...(userCanManageOnEnvironment ? [{
        key: 'navigation-admin',
        label: Language.get("navigation-admin"),
        icon: <FlexDiv><IconContainer margin={10}><AdjustmentsIcon/></IconContainer></FlexDiv>,
        children: availableAdminMenu
      }] : []),
    ]}
  />
})

export default AdminMenu