import {ExpandedWorkspace, SummarizedWorkspace} from "../redux/models/workspace";
import {buildWorkspaceUri} from "./buildWorkspaceUri";

export enum AdminPath {
  "workspace" = "workspace",
  "datamodel" = "datamodel",
  "user" = "user",
}

export const buildWorkspaceAdminUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  additionnalParams?: string) => {
  return buildWorkspaceUri(workspace, additionnalParams ? `admin/${additionnalParams}` : 'admin')
}

export const buildWorkspaceAdminDatamodelUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  code: string) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.datamodel}/${code}`)
}

export const buildWorkspaceAdminWorkspaceUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  code: string) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.workspace}/${code}`)
}

export const buildWorkspaceAdminUserUri = (
  workspace: ExpandedWorkspace | SummarizedWorkspace,
  code: string) => {
  return buildWorkspaceAdminUri(workspace, `${AdminPath.user}/${code}`)
}
