import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from "react-redux"
import {getClientAllowedEmailFqdns, getPermission} from "redux/appEnvironment.selector"
import {getAcesByUserId, getAssocClients} from "redux/user.selector"
import UserForm from "./UserForm"
import {Button, notification, TableColumnProps} from 'antd'
import styled from "styled-components"
import {buildAndBatch} from "commons/batch"
import {
  IconContainer,
  BironBreadcrumb,
  AdministrationLayout,
  ContentCard,
  BironBreadCrumbTitle,
  BironTable, Confirm
} from "@biron-data/react-components"
import Language from "language"
import NewUacForm from "./NewUacForm"
import useDispatch from "hooks/useDispatch"
import {
  UserAddIcon,
  BanIcon, TrashIcon,
} from "@heroicons/react/outline"
import {useNavigate} from "react-router-dom"
import {ModelUser} from "redux/models/user"
import {ACES, DataSourceEntry, GetComponentProps, UAC, User} from "components/admin/user/UserManager.types"
import {PermissionsEnum} from "redux/models/appEnvironment"
import {filterData, Search, SearchRef} from "../../search/Search";
import UserManagerInfo from "./UserManager.Info";
import {getUserPosition} from "./UserManager.utils";
import useUACWithAces, {UserPosition} from "../../../hooks/useUACWithAces";
import DatamodelRights from "./DatamodelRights";
import WorkspaceRights from "./WorkspaceRights";
import {Restore} from "@biron-data/icons/dist/outlined"
import {useGetDatamodelRolesSynthesis, useGetWorkspaceRolesSynthesis} from "./UserDetails.hooks";
import AdminBreadcrumb from "../Admin.Breadcrumb";

const UserManager = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const uacs: UAC[] = useSelector(getAssocClients)
  const acesByUserId: { [p: string]: ACES[] } = useSelector(getAcesByUserId)
  const getPermissionSelector = useSelector(getPermission)
  const clientAllowedEmailFqdns = useSelector(getClientAllowedEmailFqdns) as string[]
  const [createNewUac, setCreateNewUac] = useState(false)
  const [editedUac, setEditedUac] = useState<UAC>()
  const [loading, setLoading] = useState(true)
  const userCanAssocToEnvironment = getPermissionSelector(PermissionsEnum.userCanAssocToEnvironment)
  const userCanManageAdvancedFeature = getPermissionSelector(PermissionsEnum.userCanManageAdvancedFeature)

  const loadData = useCallback(() => {
    buildAndBatch([
      dispatch.user.loadAssocClient({forBatch: true}),
      dispatch.user.loadAces({forBatch: true}),
      () => setLoading(false),
    ])
  }, [dispatch.user])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleClickNewUser = useCallback(() => {
    setEditedUac(Object.assign({} as UAC, {user: {}}))
  }, [])
  const handleClickNewUac = useCallback(() => {
    setCreateNewUac(true)
  }, [])

  const handleActiveChange = useCallback((uac: UAC, active: boolean) => buildAndBatch([
    () => notification.success({
      message: Language.get(`admin.user.${active ? 'actions.activeUser' : 'actions.inactiveUser'}`),
      description: Language.get('common.notificationSuccess.description.action'),
    }),
    dispatch.user.updateAssocClientActive({
      forBatch: true,
      uac,
      active,
    }),
  ]), [dispatch])

  const handleResetPassword = useCallback((user: User) => buildAndBatch([
    () => setEditedUac(undefined),
    () => dispatch.user.resetPassword(user)
      .then(() => {
        notification.success({
          message: Language.get(`admin.user.actions.resetPasswordSuccess.message`),
          description: Language.get(`admin.user.actions.resetPasswordSuccess.description`, {user}),
        })
      }),
  ]), [dispatch])


  const handleUserFormConfirm = useCallback(
    (data: ModelUser) => {
      return editedUac?.user.id
          ? dispatch.user.updateUser({
            id: editedUac.user.id,
            data,
          }).then(() => {
          setEditedUac(undefined)
        })
          : dispatch.user.createUser({data}).then((uac) => {
            setEditedUac(undefined)
            navigate(`${uac.id}`)
          })
    }
    , [dispatch.user, editedUac, navigate])
  const handleUserFormCancel = useCallback(() => {
    setEditedUac(undefined)
  }, [])

  const handleNewUacFormConfirm = useCallback(
    ({userEmail}: { userEmail: string }) => dispatch.user.createAssocClient({userEmail})
      .then((uac) => {
        setCreateNewUac(false)
        navigate(`${uac.user.id}`)
      })
    , [dispatch.user, navigate])

  const handleNewUacFormCancel = useCallback(() => {
    setCreateNewUac(false)
  }, [])

  const addUserActions = useMemo(() => {
    const basicAdd = <FlexButton type={"primary"} key="actionCreateUser"
                                 onClick={handleClickNewUser}><IconContainer
      margin={10}><UserAddIcon/></IconContainer>{Language.get('admin.user.actions.createUser')}</FlexButton>
    if (userCanAssocToEnvironment) {
      return [basicAdd,
        <FlexButton type={"primary"} key="actionCreateUac"
                    onClick={handleClickNewUac}><IconContainer
          margin={10}><UserAddIcon/></IconContainer>{Language.get('admin.user.actions.createUac')}</FlexButton>,
      ]
    } else {
      return [basicAdd]
    }
  }, [userCanAssocToEnvironment, handleClickNewUser, handleClickNewUac])

  const searchRef = useRef<SearchRef>(null)

  const uacWithAces = useUACWithAces(uacs, acesByUserId)

  const data: DataSourceEntry[] = useMemo(() => uacWithAces.map(e => ({
    ...e,
    permUserCanEdit: userCanManageAdvancedFeature
      || (e.user.email && clientAllowedEmailFqdns.includes(e.user.email.substr(e.user.email.indexOf('@') + 1))),
  })), [uacWithAces, clientAllowedEmailFqdns, userCanManageAdvancedFeature])

  const [dataSource, setDataSource] = useState<DataSourceEntry[]>(data)

  useEffect(() => {
    setDataSource(data)
    searchRef.current?.clearSearch()
  }, [data])

  const handleDelete = useCallback((uac: UAC) => {
    setEditedUac(undefined)
    dispatch.user.deleteAssocClient({
      forBatch: true,
      uac,
    })
    setDataSource(dataSource.filter(dataSourceEntry => dataSourceEntry.user.id !== uac.user.id))
  }, [dataSource, dispatch.user])

  const getWorkspaceRolesSynthesis = useGetWorkspaceRolesSynthesis()
  const getDatamodelRolesSynthesis = useGetDatamodelRolesSynthesis()

  const columns: TableColumnProps<DataSourceEntry>[] = useMemo(() => ([
    {
      title: Language.get('admin.user.columns.user'),
      dataIndex: ['user', 'firstName'],
      width: "40%",
      render: function render(_, uac) {
        return <UserManagerInfo user={uac.user} displayEmail={true}
                                onUserNameClick={() => navigate(`${uac.user.id}`)}/>
      },
    },
    {
      title: Language.get("admin.user.columns.position"),
      dataIndex: ['user', 'position'],
      className: 'verticalAlignTop',
      width: "20%",
      filters: Object.keys(UserPosition).map((position) => ({
        text: Language.get(`admin.user.position.${position}`),
        value: position,
      })),
      onFilter: (value, record) => record.user.position === value.toString(),
      render: (position) =>
        getUserPosition(position),
    },
    {
      title: Language.get("admin.user.columns.rights"),
      width: "30%",
      className: 'verticalAlignTop',
      render: (_, uac) => <FlexDiv>
        <WorkspaceRights
          roles={getWorkspaceRolesSynthesis(uac.user.aces)}
          layout={'row'} tooltipPlacement={"left"}/>
        <DatamodelRights
          roles={getDatamodelRolesSynthesis(uac.user.aces)}
          layout={'row'} tooltipPlacement={"left"}/>
      </FlexDiv>,
    },
    {
      title: Language.get("admin.user.columns.actions"),
      width: "10%",
      className: 'verticalAlignTop',
      render: function render(_, uac) {
        if (!uac.active) {
          return <FlexEndDiv>
            <IconContainer color={"var(--primary)"} clickable={true} onClick={() => {
              handleActiveChange(uac, true)
            }}><Restore/></IconContainer>
            <Confirm
              title={Language.get('admin.user.confirm.title', uac.user.email)}
              trigger={<IconContainer color={"red"} clickable={true}><TrashIcon/></IconContainer>}
              on={{
                cancel: () => {
                  setEditedUac(undefined)
                },
                open: () => {},
                ok: async () => {
                  handleDelete(uac)
                },
              }}/>
          </FlexEndDiv>
        }
        return <FlexEndDiv>
          <IconContainer color={"red"} clickable={true} onClick={() => {
            handleActiveChange(uac, false)
          }}><BanIcon /></IconContainer>
        </FlexEndDiv>
      },
    },
  ]), [getDatamodelRolesSynthesis, getWorkspaceRolesSynthesis, handleActiveChange, handleDelete, navigate])

  const onRow: GetComponentProps<DataSourceEntry> = useCallback((record) => {
    return {
      onClick: () => navigate(`${record.user.id}`)
    }
  }, [navigate])

  return <AdministrationLayout breadcrumb={<AdminBreadcrumb
  category={{
    title: Language.get('admin.user.breadcrumb.title'),
    link: 'admin/user'
  }}/>}>
    <ContentCard title={Language.get('admin.user.content.title')} actions={[
      addUserActions
    ]}>
        <Search ref={searchRef} onChange={(newValues) => setDataSource(filterData(data, (record) => [
          record.user.email,
          record.user.firstName,
          record.user.fullName,
          record.user.lastName,
        ], newValues))} width={'calc(100% - 25px)'} searchedFieldKey={"admin.user.columns.user"}/>
        <BironTable {...{
          size: 'small',
          bordered: false,
          rowHeight: 76,
          columns,
          loading,
          dataSource,
          onRow,
          rowKey: 'id',
        }}/>

    </ContentCard>
    <UserForm {...{
      uac: editedUac,
      getPermission: getPermissionSelector,
      clientAllowedEmailFqdns,
      onActiveChange: handleActiveChange,
      onResetPassword: handleResetPassword,
      onDelete: handleDelete,
      onConfirm: handleUserFormConfirm,
      onCancel: handleUserFormCancel,
    }}/>
    <NewUacForm {...{
      visible: createNewUac,
      onConfirm: handleNewUacFormConfirm,
      onCancel: handleNewUacFormCancel,
    }}/>
  </AdministrationLayout>
}

export default UserManager

const FlexButton = styled(Button)`
  display: flex;
`

const FlexDiv = styled.div`
    display: flex;
    justify-content: start;
    gap: 10px;
`

const FlexEndDiv = styled(FlexDiv)`
    justify-content: end;
`