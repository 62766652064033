import {Select} from "antd";
import React, {memo, useCallback, useMemo} from "react";
import Language from "../../../language";
import {WorkspaceRoles} from "./UserManager.types";

interface Props {
  roles: WorkspaceRoles[]
  onUpdate: (roles: WorkspaceRoles[]) => void
}

const WorkspaceRightUpdater = memo<Props>(({
                                  roles,
                                  onUpdate
                               }) => {
  const selectedRoles = useMemo(() => {
    if(roles.includes(WorkspaceRoles.WORKSPACE_MANAGER)){
      return WorkspaceRoles.WORKSPACE_MANAGER
    }
    if(roles.includes(WorkspaceRoles.WORKSPACE_WRITER)){
      return WorkspaceRoles.WORKSPACE_WRITER
    }
    if(roles.includes(WorkspaceRoles.WORKSPACE_READER)){
      return WorkspaceRoles.WORKSPACE_READER
    }
    return null
  }, [roles])

  const onChange = useCallback((newRoles: WorkspaceRoles | null) => {
    if (newRoles === null) {
      return onUpdate([])
    }
    if(newRoles.includes(WorkspaceRoles.WORKSPACE_MANAGER)){
      return onUpdate([WorkspaceRoles.WORKSPACE_READER, WorkspaceRoles.WORKSPACE_WRITER, WorkspaceRoles.WORKSPACE_MANAGER])
    } else if(newRoles.includes(WorkspaceRoles.WORKSPACE_WRITER)){
      return onUpdate([WorkspaceRoles.WORKSPACE_READER, WorkspaceRoles.WORKSPACE_WRITER])
    } else if(newRoles.includes(WorkspaceRoles.WORKSPACE_READER)){
      return onUpdate([WorkspaceRoles.WORKSPACE_READER])
    }
    return onUpdate([])
  }, [onUpdate])

  return <Select value={selectedRoles} onChange={onChange} options={[{
    value: null,
    label: Language.get("admin.workspace.rights.noAccess.title")
  }, {
    value: WorkspaceRoles.WORKSPACE_READER,
    label: Language.get("admin.workspace.rights.reader.title")
  }, {
    value: WorkspaceRoles.WORKSPACE_WRITER,
    label: Language.get("admin.workspace.rights.writer.title")
  }, {
    value: WorkspaceRoles.WORKSPACE_MANAGER,
    label: Language.get("admin.workspace.rights.manager.title")
  }]}></Select>
})

export default WorkspaceRightUpdater