import React from 'react'
import {
  AdministrationLayout,
  ContentCard,
  IconContainer,
} from "@biron-data/react-components";
import AdminBreadcrumb from "./Admin.Breadcrumb";
import Language from "../../language";
import {
  CalendarIcon,
  CollectionIcon,
  DatabaseIcon,
  LockOpenIcon,
  UserIcon,
  ExternalLinkIcon
} from "@heroicons/react/outline";
import {Col, Row} from 'antd';
import {buildWorkspaceAdminUri, buildWorkspaceAdminWorkspaceUri} from "../../commons/buildWorkspaceAdminUri";
import {useSelector} from "react-redux";
import {getCurrentWorkspaceExpanded} from "../../redux/workspace.selector";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";


const AdminHome = () => {
  const workspace = useSelector(getCurrentWorkspaceExpanded)
  const navigate = useNavigate()

  const elements = [
    {
      icon: UserIcon,
      title: Language.get("admin.home.users"),
      link: workspace ? buildWorkspaceAdminUri(workspace, "user") : ""
    },
    {
      icon: LockOpenIcon,
      title: Language.get("admin.home.rights", workspace?.name ?? ""),
      link: workspace ? buildWorkspaceAdminWorkspaceUri(workspace, `${workspace?.id}`) : ""
    },
    {
      icon: CollectionIcon,
      title: Language.get("admin.home.workspaces"),
      link: workspace ? buildWorkspaceAdminUri(workspace, `workspaces`) : ""
    },
    {
      icon: DatabaseIcon,
      title: Language.get("admin.home.datamodels"),
      link: workspace ? buildWorkspaceAdminUri(workspace, `datamodel`) : ""
    },
    {
      icon: CalendarIcon,
      title: Language.get("admin.home.periods"),
      link: workspace ? buildWorkspaceAdminUri(workspace, `periods`) : ""
    },
    {
      icon: ExternalLinkIcon,
      title: Language.get("admin.home.exports"),
      link: workspace ? buildWorkspaceAdminUri(workspace, `exports`) : ""
    }
  ]
  return <AdministrationLayout breadcrumb={<AdminBreadcrumb />}>
    <ContentCard title={Language.get("admin.title")} width={"30%"} actions={[]}>
      <FlexContainer>
        {elements.map(element => <ClickableRow key={element.title} onClick={() => {
          navigate(element.link)
        }}>
          <Col span={4}>
            <IconContainer clickable={true} color={"var(--primary)"}><element.icon/></IconContainer>
          </Col>
          <Col span={20}>
            {element.title}
          </Col>
        </ClickableRow>)}
      </FlexContainer>
    </ContentCard>
  </AdministrationLayout>
}

export default AdminHome


const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const ClickableRow = styled(Row)`
  :hover {
      color: var(--primary);
      cursor: pointer;
  }
`