import React from "react"
import {
  AdministrationLayout,
  IconContainer,
  ContentCard,
  BironList,
  BironBreadCrumbTitle, BironBreadcrumb, JustifyContentProperty
} from "@biron-data/react-components";
import {QuestionMarkCircleIcon} from "@heroicons/react/outline";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getDataModels} from "../../redux/environment.selector";
import Language from "../../language";
import {buildWorkspaceAdminDatamodelUri} from "../../commons/buildWorkspaceAdminUri";
import {getCurrentWorkspaceExpanded} from "../../redux/workspace.selector";
import AdminBreadcrumb from "components/admin/Admin.Breadcrumb";

const DatamodelList = () => {
  const navigate = useNavigate()
  const datamodels = useSelector(getDataModels)
  const workspace = useSelector(getCurrentWorkspaceExpanded)
  return <AdministrationLayout breadcrumb={<AdminBreadcrumb
    category={{
      link: "admin/datamodels",
      title: Language.get("admin.datamodels.breadcrumb.title")
    }} />}>
    <ContentCard title={Language.get("admin.datamodels.content.title")} actions={[]}>
      <BironList options={datamodels.map(datamodel => ({
        key: datamodel.code,
        elements: [
          {
            content: <div onClick={() => {
              if (workspace) {
                navigate(buildWorkspaceAdminDatamodelUri(workspace, datamodel.code))
              }
            }}>{datamodel.alias}</div>,
            color: "var(--primary)",
            span: 4,
          },
            {
                content: datamodel.description,
                span: 18,
            },
          {
            content: <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><QuestionMarkCircleIcon/></IconContainer>,
            span: 2,
            align: JustifyContentProperty.end
          }
        ],
        actions: []
      }))}/>
    </ContentCard>
  </AdministrationLayout>
}

export default DatamodelList
