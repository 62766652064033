import {Select} from "antd";
import React, {memo, useCallback, useMemo} from "react";
import Language from "../../../language";
import {DatamodelRoles} from "./UserManager.types";

interface Props {
  roles: DatamodelRoles[]
  onUpdate: (newRoles: DatamodelRoles[]) => void
}

const DatamodelRightUpdater = memo<Props>(({
                                  roles,
                                             onUpdate
                               }) => {
  const selectedRoles = useMemo(() => {
    if(roles.includes(DatamodelRoles.DATAMODEL_EXPLORER)){
      return DatamodelRoles.DATAMODEL_EXPLORER
    }
    if(roles.includes(DatamodelRoles.DATAMODEL_VIEWER)){
      return DatamodelRoles.DATAMODEL_VIEWER
    }
    return null
  }, [roles])

  const onChange = useCallback((newRoles: DatamodelRoles | null) => {
    if (newRoles === null) {
      return onUpdate([])
    }
    if(newRoles.includes(DatamodelRoles.DATAMODEL_EXPLORER)){
      return onUpdate([DatamodelRoles.DATAMODEL_EXPLORER, DatamodelRoles.DATAMODEL_VIEWER])
    } else if(newRoles.includes(DatamodelRoles.DATAMODEL_VIEWER)){
      return onUpdate([DatamodelRoles.DATAMODEL_VIEWER])
    }
    return onUpdate([])
  }, [onUpdate])

  return <Select value={selectedRoles} onChange={onChange} options={[{
    value: null,
    label: Language.get("admin.datamodels.rights.noAccess")
  }, {
    value: DatamodelRoles.DATAMODEL_VIEWER,
    label: Language.get("admin.datamodels.rights.viewer")
  }, {
    value: DatamodelRoles.DATAMODEL_EXPLORER,
    label: Language.get("admin.datamodels.rights.explorer")
  }]}></Select>
})

export default DatamodelRightUpdater