import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {Col, Row, TableColumnProps} from "antd";
import styled from "styled-components";
import {
    AdministrationLayout,
    InformationsContainer,
    ContentContainer,
    BironBreadCrumbTitle, ContentCard, BironTable, BironBreadcrumb
} from "@biron-data/react-components";
import {useSelector} from "react-redux";
import {getAvailables} from "../../redux/workspace.selector";
import {useNavigate, useParams} from "react-router-dom";
import {getDataModels} from "../../redux/environment.selector";
import {ACES, Roles, UAC} from "../admin/user/UserManager.types";
import {getAcesByUserId, getAssocClients} from "../../redux/user.selector";
import {buildAndBatch} from "../../commons/batch";
import useDispatch from "../../hooks/useDispatch";
import useUACWithAces, {UACWithAces} from "../../hooks/useUACWithAces";
import WorkspaceRightUpdater from "../admin/user/WorkspaceRightUpdater";
import useHandleUserAcesModification from "../../hooks/useHandleUserAcesModification";
import Language from "../../language";
import {filterData, Search, SearchRef} from "../search/Search";
import UserManagerInfo from "../admin/user/UserManager.Info";
import {isWorkspaceRole} from "../admin/user/UserManager.utils";
import {buildWorkspaceAdminUserUri} from "../../commons/buildWorkspaceAdminUri";
import AdminBreadcrumb from "../admin/Admin.Breadcrumb";

const WorkspaceManager = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = params
    const workspaces = useSelector(getAvailables)
    const workspace = workspaces.find(w => w.id === Number(id))
    const datamodels = useSelector(getDataModels)
    const uacs: UAC[] = useSelector(getAssocClients)
    const acesByUserId: { [p: string]: ACES[] } = useSelector(getAcesByUserId)
    const uacWithACES = useUACWithAces(uacs, acesByUserId)
    const searchRef = useRef<SearchRef>(null)
    const uacWithCurrentWorkspaceAces = useMemo(() => uacWithACES.map(uac => {
        return {
            ...uac,
            user: {
                ...uac.user,
                aces: uac.user.aces.filter(ace => ace.targetId === Number(id))
            }
        }
    }), [id, uacWithACES])
    const [data, setData] = useState(uacWithCurrentWorkspaceAces)
    const [loading, setLoading] = useState(true)

    const handleOnConfirm = useHandleUserAcesModification()

    const onUpdateWorkspaceRights = useCallback((actualUac: UACWithAces, newRoles: Roles[], workspaceId: number) => {
        return handleOnConfirm(actualUac, {
            [`workspace:${workspaceId}`]: newRoles
        })
    }, [handleOnConfirm])

    useEffect(() => {
        buildAndBatch([
            dispatch.user.loadAssocClient({forBatch: true}),
            dispatch.user.loadAces({forBatch: true}),
            () => setLoading(false),
        ])
    }, [dispatch])
    useEffect(() => {
        setData(uacWithCurrentWorkspaceAces)
        searchRef.current?.clearSearch()
    }, [uacWithCurrentWorkspaceAces])
    const columns: TableColumnProps<UACWithAces>[] = useMemo(() => ([
        {
            title: 'Nom',
            dataIndex: ['user', 'fullName'],
            width: `${30}%`,
            render: (a, b) => <UserManagerInfo user={b.user} displayEmail={false} onUserNameClick={() => {
                if (workspace) {
                    navigate(buildWorkspaceAdminUserUri(workspace, `${b.user.id}`))
                }
            }}/>
        },
        {
            title: 'Email',
            dataIndex: ['user', 'email'],
            width: `${50}%`,
        },
        {
            title: 'Acces',
            width: `${20}%`,
            dataIndex: ['user', 'aces'],
            render: (a, b) => <WorkspaceRightUpdater roles={b.user.aces[0]?.roles?.filter(isWorkspaceRole) ?? []} onUpdate={(newRoles) => {
                if (workspace) {
                    setLoading(true)
                    onUpdateWorkspaceRights(b, newRoles, workspace.id)?.then(() => {
                        setLoading(false)
                    }).catch(() => setLoading(false))
                }
            }}/>
        },
    ]), [navigate, onUpdateWorkspaceRights, workspace])

    return <AdministrationLayout breadcrumb={<AdminBreadcrumb
      category={{
          title: "Workspaces",
          link: 'admin/workspaces'
      }} detail={{
          title: workspace?.name ?? ""
    }}/>}>
        <InformationsContainer>
            <ContentCard title={workspace?.name}>
                        <FlexContainer gap={10}>
                            <Row>
                                <Col span={8}>
                                    <span>Datamodel:</span>
                                </Col>
                                <Col span={16}>
                                    <span>{datamodels.find(d => d.code === workspace?.datamodelCode)?.alias}</span>
                                </Col>
                            </Row>

                        </FlexContainer>
            </ContentCard>
        </InformationsContainer>
        <ContentContainer>
            <ContentCard title={"Utilisateurs"}>
                    <Search ref={searchRef} onChange={(newValues) => {
                        setData(filterData(uacWithCurrentWorkspaceAces, (record) => [
                            record.user.firstName,
                            record.user.fullName,
                            record.user.lastName,
                        ], newValues))
                    }} width={'calc(100% - 25px)'} searchedFieldKey={"admin.user.columns.user"}/>
                    <BironTable
                          columns={columns}
                          loading={loading}
                          dataSource={data}
                          size="middle">
                    </BironTable>
                </ContentCard>
        </ContentContainer>
    </AdministrationLayout>
}

export default WorkspaceManager


const FlexContainer = styled.div<{gap: number}>`
  display: flex;
  flex-direction: column;
    gap:${({gap}) => gap}px;
` // TODO extract in react-components

export const SizeReference = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
`// TODO extract in react-components