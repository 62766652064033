import React, {memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Language from "language"
import styled from "styled-components"
import {
  AdministrationLayout,
  BironBreadcrumb,
  ContentContainer, IconContainer,
  InformationsContainer,
  BironBreadCrumbTitle, ContentCard, TableLink, ValueDescription, JustifyContentProperty, BironTable
} from "@biron-data/react-components"
import {ACES, Roles, UAC, User} from "./UserManager.types";
import {Checkbox, Col, Row, Select, TableColumnProps} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAcesByUserId, getAssocClients} from "../../../redux/user.selector";
import {getUserPosition, isDatamodelRole, isWorkspaceRole} from "./UserManager.utils";
import useUACWithAces from "../../../hooks/useUACWithAces";
import DatamodelRights from "./DatamodelRights";
import {getAvailables, getAvailablesForAce, getCurrentWorkspace} from "../../../redux/workspace.selector";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {getCurrentEnvironmentId, getDataModels} from "../../../redux/environment.selector";
import {ChevronRightIcon} from "@heroicons/react/solid";
import WorkspaceRights from "./WorkspaceRights";
import WorkspaceRightUpdater from "./WorkspaceRightUpdater";
import {Submenu} from "@biron-data/icons";
import DatamodelRightUpdater from "./DatamodelRightUpdater";
import useDispatch from "../../../hooks/useDispatch";
import {buildAndBatch} from "../../../commons/batch";
import {
  buildWorkspaceAdminDatamodelUri,
  buildWorkspaceAdminWorkspaceUri
} from "../../../commons/buildWorkspaceAdminUri";
import {locales} from "../../../commons/locales/locales";
import {
  useDatamodelsWithWorkspace, useGetDatamodelRolesSynthesis,
  useGetWorkspaceRolesSynthesis,
  useUpdateDatamodelRights,
  useUpdateWorkspaceRights
} from "./UserDetails.hooks";
import {ClientWorkspace} from "../../../redux/models/appEnvironment";
import AdminBreadcrumb from "../Admin.Breadcrumb";

interface Props {
}

interface DataType {
  roles: Roles[] | undefined
  workspaces: (Pick<ClientWorkspace, "id" | "name"> & {
    roles: Roles[] | undefined
  })[]
  code: string
  alias: string
  description?: string | undefined
  includedMetrics?: any
  includedDimensions?: any
}

const UserDetails = memo<Props>(function UserDetails() {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {uid} = params
  const uacs: UAC[] = useSelector(getAssocClients)
  const workspace = useSelector(getCurrentWorkspace)
  const acesByUserId: { [p: string]: ACES[] } = useSelector(getAcesByUserId)
  const availableWorkspacesForAce = useSelector(getAvailablesForAce)
  const workspaceswithDatamodelCode = useSelector(getAvailables)
  const UACWithAces = useUACWithAces(uacs, acesByUserId)
  const uac = UACWithAces.find(e => e.user.id === Number(uid))
  const datamodels = useSelector(getDataModels)
  const environmentId = useSelector(getCurrentEnvironmentId)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    buildAndBatch([
      dispatch.user.loadAssocClient({forBatch: true}),
      dispatch.user.loadAces({forBatch: true}),
      () => setLoading(false),
    ])
  }, [dispatch])

  const datamodelsWithWorkspace = useDatamodelsWithWorkspace(
    datamodels,
    uac,
    availableWorkspacesForAce,
    workspaceswithDatamodelCode
  )

  const getWorkspaceRolesSynthesis = useGetWorkspaceRolesSynthesis()
  const getDatamodelRolesSynthesis = useGetDatamodelRolesSynthesis()

  const onUpdateWorkspaceRights = useUpdateWorkspaceRights(uac)

  const onUpdateDatamodelRights = useUpdateDatamodelRights(uac, environmentId)

  const columns: TableColumnProps<DataType>[] = useMemo(() => ([
    {
      title: Language.get('admin.user.columns.name'),
      dataIndex: 'alias',
      width: "40%",
      render: (a, b) => <TableLink link={workspace ? buildWorkspaceAdminDatamodelUri(workspace, b.code) : ""}>{b.alias}</TableLink>
    },
    {
      title: Language.get('admin.user.columns.access'),
      dataIndex: 'roles',
      width: "45%",
      render: (a, b) => <DatamodelRightUpdater roles={b.roles?.filter(isDatamodelRole) ?? []} onUpdate={(newRoles) => {
        setLoading(true)
        onUpdateDatamodelRights(newRoles, b.code)?.then(() => {
          setLoading(false)
        }).catch(() => setLoading(false))
      }} />
    },
    {
      title: Language.get('admin.user.columns.rights'),
      width: "15%",
      render: (aces, d) => <DatamodelRights
        roles={d.roles?.filter(isDatamodelRole) ?? []}
        layout={'row'}
        justifyContent={JustifyContentProperty.end}
        tooltipPlacement={"left"}/>,
    },
  ]), [onUpdateDatamodelRights, workspace])

  const handleUserFormConfirm = useCallback(
    (newUserData: User) => {
      return dispatch.user.updateUser({
            id: newUserData.id,
            data: newUserData,
          })
    }
    , [dispatch])

  return uac && <AdministrationLayout breadcrumb={
    <AdminBreadcrumb
      category={{
        title: Language.get('admin.user.breadcrumb.title'),
        link: 'admin/user'
      }}
      detail={{
        title: uac.user.fullName,
      }}/>
  }>
    <InformationsContainer>
      <ContentCard title={uac.user.fullName}>
            <FlexContainer gap={20}>
                <FlexContainer gap={10}>
                    <Row>
                        <Col span={8}>
                            <span>{Language.get("admin.user.content.email")}</span>
                        </Col>
                        <Col span={16}>
                            <span>{uac.user.email}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <span>{Language.get("admin.user.content.statut")}</span>
                        </Col>
                        <Col span={16}>
                            <span>{getUserPosition(uac.user.position)}</span>
                        </Col>
                    </Row>
                </FlexContainer>

                <Row>
                    <Title>
                        <span>{Language.get("admin.user.content.settings")}</span>
                    </Title>
                </Row>
                <FlexContainer gap={10}>
                    <Row>
                        <Col span={8}>
                            <span>{Language.get("admin.user.content.ticketAccess")}</span>
                        </Col>
                        <Col span={16}>
                            <span><Checkbox value={uac.user.powerUser} onChange={(state) => handleUserFormConfirm({
                              ...uac?.user,
                              powerUser: state.target.checked
                            })}/></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <span>{Language.get("admin.user.content.language.title")}</span>
                        </Col>
                        <Col span={16}>
                            <span>
                              <Select value={uac.user.locale} options={[{
                                value: locales[1],
                                label: Language.get("admin.user.content.language.english")
                              }, {
                                value: locales[0],
                                label: Language.get("admin.user.content.language.french")
                              }]} onChange={(state) => handleUserFormConfirm({
                                ...uac?.user,
                                locale: state
                              })}/>
                            </span>
                        </Col>
                    </Row>
                </FlexContainer>
                    <Row>
                        <Title>
                            <span>{Language.get("admin.user.content.rights")}</span>
                        </Title>
                    </Row>
                    <Row>
                        <FlexContainer gap={10}>
                            <WorkspaceRights
                                roles={getWorkspaceRolesSynthesis(uac.user.aces)}
                                layout={'column'}
                            tooltipPlacement={"right"}/>
                            <DatamodelRights
                                roles={getDatamodelRolesSynthesis(uac.user.aces)}
                                layout={'column'} tooltipPlacement={"right"}/>
                        </FlexContainer>
                    </Row>
            </FlexContainer>
        </ContentCard>
    </InformationsContainer>
    <ContentContainer>
        <ContentCard title={Language.get("admin.user.content.userRights")} actions={[
          <ValueDescription
            key={Language.get("admin.user.content.rightsInfoTitle")}
            placement={"left"}
            additionalTrigger={Language.get("admin.user.content.rightsInfoTitle")}
            description={Language.get("admin.user.content.rightsInfoDescription")}/>
        ]}>
          <BironTable
              columns={columns}
              loading={loading}
              useVirtualGrid={false}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (record) : ReactNode[] => record.workspaces.map(w => <RowWithCenteredContent key={w.id}>
                  <Col span={11}>
                    <FlexDiv>
                      <div style={{marginRight: 10}}><Submenu/></div>
                      <TableLink
                        link={workspace ? buildWorkspaceAdminWorkspaceUri(workspace, `${w.id}`) : ''}>{w.name}</TableLink>
                    </FlexDiv>
                  </Col>
                  <Col span={10}>
                    <WorkspaceRightUpdater roles={w.roles?.filter(isWorkspaceRole) ?? []} onUpdate={(newRoles) => {
                      setLoading(true)
                      onUpdateWorkspaceRights(newRoles, w.id)?.then(() => {
                        setLoading(false)
                      }).catch(() => setLoading(false))
                    }}/>
                  </Col>
                  <Col span={3}>
                    <WorkspaceRights roles={w.roles?.filter(isWorkspaceRole) ?? []} layout={"row"} justifyContent={JustifyContentProperty.end} tooltipPlacement={"left"}/>
                  </Col>

                </RowWithCenteredContent>),
                rowExpandable: (record) => record.alias !== 'Not Expandable',
                expandIcon: ({expanded, onExpand, record}) =>
                  expanded ? (
                    <IconContainer onClick={e => onExpand(record, e)}>
                      <ChevronDownIcon />
                    </IconContainer>
                  ) : (
                    <IconContainer onClick={e => onExpand(record, e)}>
                      <ChevronRightIcon />
                    </IconContainer>
                  )
              }}
              dataSource={datamodelsWithWorkspace}
              size="middle">
          </BironTable>
        </ContentCard>
    </ContentContainer>
  </AdministrationLayout>
})

export default UserDetails

const FlexDiv = styled.div`
    display: flex;
    padding-left: 10%;
`

const RowWithCenteredContent = styled(Row)`
    align-items: center;
`

const Title = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -2%;
`// TODO extract in react-components

const FlexContainer = styled.div<{gap: number}>`
  display: flex;
  flex-direction: column;
    gap:${({gap}) => gap}px;
` // TODO extract in react-components


export const SizeReference = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
`// TODO extract in react-components